import React, { useContext, useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import {
  Container,
  Flex,
  Box,
  SimpleGrid,
  FormControl,
  Input,
  Select,
} from '@chakra-ui/react'
import { H1, H3 } from '../theme/Headings'
import { spacing, colors, breakpoints } from '../theme/themeAlopex'
// import BackgroundImage from 'gatsby-background-image'
import ColorContext from '../context/ColorContext'
// import NewsletterIcon from '../components/NewsletterIcon'
// gsap
import {gsap} from 'gsap'

const NewsletterPage = ({ data }) => {
  const { color } = useContext(ColorContext)
  const [expanded, setExpanded] = useState(false)
  const refOpener = useRef(null)
	const refOpener2 = useRef(null)
	const refLetter = useRef(null)

	const openLetter = () => {
		gsap.set(refOpener.current, {transformOrigin: '50% 100%', scaleY: '-1'})
		gsap.set(refOpener2.current, {transformOrigin: '50% 100%', scaleY: '0'})
		const tlNL = new gsap.timeline({})
		tlNL.to(refOpener.current, {
			scaleY: 0,
		})
		tlNL.to(refOpener2.current, {
			scaleY: 1,
		})
		tlNL.to(refLetter.current, {
			y: '-44px'
		})
	}

	useEffect(() =>  {
		openLetter()
	}, [])

  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  return (
    <Layout>
      <SEO title='The Alopex Designers & Developers - Alopex' />
      <Box backgroundColor={color.alpha} pt={spacing.header} pb={spacing.section} color={colors.brand.white}>
          <Container maxWidth={breakpoints.lg} textAlign="center">
            <Flex justifyContent="center" mb={spacing.elements}>
              {/* <NewsletterIcon maxWidth="100px" /> */}


              <svg
                id="IconNewsletter"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 109.2593 123.8152"
                x="0px"
                y="0px"
                style={{
                  maxWidth: '100px',
                  width: '100%',
                }}
              >
                <defs>
                  <mask id="letterMask" maskUnits="userSpaceOnUse">
                    <polygon fill="#FFFFFF" points="10.5925,61.7413 54.8518,106 98.8889,61.963 98.8889,2.6143 10.5925,3.0073"/>
                  </mask>
                </defs>
                <path fill={color.bravo} d="M106.1404,120.9593H3.5627V57.1488h102.5776V120.9593z"/>
                <linearGradient id="envTop_1_" gradientUnits="userSpaceOnUse" x1="72.8449" y1="16.4082" x2="36.4004" y2="102.186">
                  <stop offset="0" style={{stopColor:color.alpha}}/>
                  <stop offset="1" style={{stopColor:color.bravo}}/>
                </linearGradient>
                <polygon id="envTop" fill="url(#envTop_1_)" points="102.1409,58.7575 7.5629,58.7575 54.8519,106.0464 "/>
                <polygon id="envOpener2" ref={refOpener2} fill={color.bravo} transformorigin="50% 100%" scaley="0" points="54.8518,2.6667 3.5627,57.1488 106.1404,57.1488 "/>
                <g id="letter" mask="url(#letterMask)">
                  <g ref={refLetter}>
                    <rect id="paper" x="15.5927" y="63.0464" fill="#FFFFFF" width="78.5185" height="101.6943"/>
                    <line id="line01" fill="none" stroke="#E5E5E5" strokeWidth="6" strokeMiterlimit="10" x1="28.4816" y1="80.8152" x2="81.2223" y2="80.8152"/>
                    <line id="line02" fill="none" stroke="#E5E5E5" strokeWidth="6" strokeMiterlimit="10" x1="28.4816" y1="95.1859" x2="81.2223" y2="95.1859"/>
                  </g>
                </g>
                <polygon id="envOpener" ref={refOpener} fill={color.bravo} transformorigin="50% 100%" scaley="-1" points="54.8518,2.6667 3.5627,57.1488 106.1404,57.1488 "/>
                <linearGradient id="envLeft_1_" gradientUnits="userSpaceOnUse" x1="29.1158" y1="60.4702" x2="4.2269" y2="106.0998">
                  <stop  offset="0" style={{stopColor:color.alpha}}/>
                  <stop  offset="1" style={{stopColor:color.bravo}}/>
                </linearGradient>
                <polygon id="envLeft" fill="url(#envLeft_1_)" points="7.5629,58.7575 36.6638,87.8583 7.5629,116.9592 "/>
                <linearGradient id="envRight_1_" gradientUnits="userSpaceOnUse" x1="78.6174" y1="60.7458" x2="105.2841" y2="98.0791">
                  <stop  offset="0" style={{stopColor:color.alpha}}/>
                  <stop  offset="1" style={{stopColor:color.bravo}}/>
                </linearGradient>
                <polygon id="envRight" fill="url(#envRight_1_)" points="102.1409,58.7575 73.04,87.8584 102.1409,116.9592 "/>
                <linearGradient id="envBottom_1_" gradientUnits="userSpaceOnUse" x1="77.1977" y1="64.3646" x2="46.4111" y2="136.8259">
                  <stop  offset="0" style={{stopColor:color.alpha}}/>
                  <stop  offset="1" style={{stopColor:color.bravo}}/>
                </linearGradient>
                <polygon id="envBottom" fill="url(#envBottom_1_)" points="54.852,106.0465 36.6637,87.8582 7.5629,116.9592 102.1408,116.9592 
                  73.04,87.8582 "/>
              </svg>




            </Flex>
            <H1 variant="pageHeader" color={colors.brand.white} textTransform="uppercase">Join the Alopex Newsletter</H1>
          </Container>
          <Container maxWidth={breakpoints.md} textAlign="center">
          <Box as="p" mb={spacing.elements}>
            Our industry is constantly evolving—we know keeping up can be a hassle.
            Join the Alopex newsletter for a semi-monthly update that covers industry
            trends, new technologies, better ways to reach your audience, and more.  
          </Box>
          <form name="NewsletterLanding" method="POST" data-netlify="true" netlify="true">
            <Flex flexDirection="row">
              <FormControl id="emailAddress" marginRight="10px" flex="3 1" isRequired>
                <Input type="email" name="email" placeholder="Your Email" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <input type="hidden" name="form-name" value="NewsletterLanding" />
              <input type="hidden" name="origin" value="newsletter_landing" />
              <Input
                type="submit"
                value="Submit"
                _hover={{
                  color: color.alpha,
                }}
                flex="1 1"
              />
            </Flex>
            <Box onClick={() => { setExpanded(!expanded) }} mt={spacing.icon} mb={spacing.icon} cursor="pointer">
              <H3>
                {`Tell us more about you ${expanded ? '-' : '+' }`}
              </H3>
            </Box>
            <Box opacity={() => { return expanded ? 1 : 0 }} height={() => { return expanded ? 'auto' : 0 }} position="relative" transition="all 0.4s ease-in-out">
              <Box as="p">
                Alopex would like to get to know you better! Let us know a few more optional details and you'll receive a surprise.
              </Box>
              <SimpleGrid columns={[1, null, 2]} gap="10px" mb="10px" mt={spacing.icon} sx={{ 'input[type="text"], select, textarea': { minHeight: '55px', color: color.alpha } }}>
                <FormControl id="firstName">
                  <Input type="text" name="First Name" placeholder="First Name" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
                </FormControl>
                <FormControl id="lastName">
                  <Input type="text" name="Last Name" placeholder="Last Name" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
                </FormControl>
                <FormControl id="birthdayMonth">
                <Select name="Birth Month" placeholder="Birthday Month" minHeight="55px" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha}>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </Select>
              </FormControl>
              <FormControl id="birthdayDay">
                <Select name="Birth Day" placeholder="Birth Day" minHeight="55px" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha}>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                  <option>16</option>
                  <option>17</option>
                  <option>18</option>
                  <option>19</option>
                  <option>20</option>
                  <option>21</option>
                  <option>22</option>
                  <option>23</option>
                  <option>24</option>
                  <option>25</option>
                  <option>26</option>
                  <option>27</option>
                  <option>28</option>
                  <option>29</option>
                  <option>30</option>
                  <option>31</option>
                </Select>
              </FormControl>
              </SimpleGrid>
              <FormControl id="jobTitle">
                <Input type="text" name="Job Title" placeholder="Job Title" mb="10px" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="companyName">
                <Input type="text" name="Company Name" placeholder="Company Name" mb="10px" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="mailingAddress">
                <Input type="text" name="Mailing Address" placeholder="Mailing Address" mb="10px" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <SimpleGrid columns={[1, null, 2]} gap="10px" mb="10px">
                <FormControl id="city">
                  <Input type="text" name="City" placeholder="City" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
                </FormControl>
                <FormControl id="state">
                  <Input type="text" name="State" placeholder="State" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
                </FormControl>
              </SimpleGrid>
              <FormControl id="postalCode">
                <Input type="text" name="Postal Code" placeholder="Postal Code" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <Input
                type="submit"
                mt="20px"
                value="Submit"
                _hover={{
                  color: color.alpha,
                }}
              />
            </Box>
          </form>
        </Container>
      </Box>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query aloNewsletter {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default NewsletterPage
